/* eslint-disable no-console */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Box, Skeleton, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { ApplicationState } from '~/store/store';
import { colors } from '~/styles/colors';
import { ProposalRoom, ProposalSectionProps } from '~/store/ducks/Proposal/types';
import ProposalService from '~/store/ducks/Proposal/services';
import { toastError } from '~/helpers/toast';

const proposalRoomPropertiesArray = [
  'internalDimension',
  'thicknessCeilingWall',
  'thicknessFloor',
  'doorsArea',
  'doorsOpenDuration',
  'staffHeat',
  'equipmentHeat',
  'roomTemperature',
  'ambientTemperature',
  'storageCapacity',
  'goodsType',
  'dailyMovement',
  'checkinTemperature',
  'processingTime',
  'safetyMargin',
  'heatLoad',
];

const proposalEquipmentPropertiesArray = [
  'evaporator',
  'evaporatorAirFlow',
  'evaporatorAirExchangeRate',
  'evaporatorEletricVoltage',
  'compressingUnit',
  'refrigerantFluid',
  // 'condenser',
  'totalCapacity',
  'compressingUnitEletricVoltage',
];

const Characteristics: React.FC<ProposalSectionProps> = ({ isExpanded }) => {
  const { rooms: proposalRooms } = useSelector((state: ApplicationState) => state.proposal);
  const [loading, setLoading] = useState<boolean>(false);
  const [templateKeys, setTemplateKeys] = useState(null);

  const parseTemplateKeys = async (data) =>
    data.reduce((acc, item: any) => {
      const key = {};
      key[item.key] = item.text;
      return {
        ...acc,
        ...key,
      };
    }, {});

  const fetchCharacteristcsTemplate = async () => {
    try {
      setLoading(true);
      const response = await ProposalService.getCharacteristicsTemplate();
      const parsedTemplate = await parseTemplateKeys(response.data);
      setTemplateKeys(parsedTemplate);
    } catch (err) {
      console.error(err);
      toastError('proposal', 'Erro ao recuperar apresentação.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      fetchCharacteristcsTemplate();
    }

    return () => setTemplateKeys(null);
  }, [isExpanded]);

  const getTemplateText = (key: string) => templateKeys[key];

  const getPropertyKey = (property: string, isVisible: boolean) => {
    const stringTable = {
      application: '/proposal/equipment-selection/application',
      internalDimension: '/proposal/equipment-selection/internal-dimesions',
      thicknessCeilingWall: isVisible ? '/proposal/equipment-selection/walls' : null,
      thicknessFloor: isVisible ? '/proposal/equipment-selection/floor' : null,
      doorsArea: isVisible ? '/proposal/equipment-selection/doors-dimensions' : null,
      doorsOpenDuration: isVisible ? '/proposal/equipment-selection/doors-opening-duration' : null,
      staffHeat: isVisible ? '/proposal/equipment-selection/staff-heatload' : null,
      equipmentHeat: isVisible ? '/proposal/equipment-selection/equipments-heatload' : null,
      roomTemperature: '/proposal/equipment-selection/internal-temperature',
      ambientTemperature: '/proposal/equipment-selection/ambient-temperature',
      storageCapacity: isVisible ? '/proposal/equipment-selection/static-capacity' : null,
      goodsType: isVisible ? '/proposal/equipment-selection/product-type' : null,
      dailyMovement: isVisible ? '/proposal/equipment-selection/product-movement' : null,
      checkinTemperature: isVisible ? '/proposal/equipment-selection/product-checkin-temperature' : null,
      processingTime: isVisible ? '/proposal/equipment-selection/processing-time' : null,
      safetyMargin: isVisible ? '/proposal/equipment-selection/security-coeficient' : null,
      heatLoad: '/proposal/equipment-selection/total-heatload',
      equipments: '/proposal/equipment-selection/equipments',
      evaporator: '/proposal/equipment-selection/condensing-unit-selection',
      evaporatorAirFlow: '/proposal/equipment-selection/evaporators-flow',
      evaporatorEletricVoltage: '/proposal/equipment-selection/evaporator-electric-tension',
      evaporatorAirExchangeRate: '/proposal/equipment-selection/air-exchange-rate',
      compressingUnit: '/proposal/equipment-selection/compressing-unit-selection',
      refrigerantFluid: '/proposal/equipment-selection/refrigerant',
      // condenser: '/proposal/equipment-selection/condenser',
      totalCapacity: '/proposal/equipment-selection/total-capacity',
      compressingUnitEletricVoltage: '/proposal/equipment-selection/compressing-unit-electric-tension',
    };

    try {
      return stringTable[property];
    } catch (e) {
      return '';
    }
  };

  const getPropertyText = (property: string) => getTemplateText(getPropertyKey(property, true));

  return (
    <Skeleton isLoaded={!loading}>
      {templateKeys && (
        <Box overflowX="auto" className="">
          <Table size="sm" variant="striped" colorScheme="blackAlpha">
            {/* Rooms */}
            <Thead>
              <Tr>
                <Th>Aplicação</Th>
                {proposalRooms?.map((room: ProposalRoom) => (
                  <Th key={room.id}>{room.name}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {proposalRoomPropertiesArray?.map(
                (property: string, index: number) =>
                  getPropertyKey(property, proposalRooms?.find((f) => f.calculationType === 1) !== undefined) !==
                    null && (
                    <Tr key={String(index)}>
                      <Td>{getPropertyText(property)}</Td>
                      {proposalRooms?.map((room: ProposalRoom) => (
                        <Td key={room.id}>{room[property]}</Td>
                      ))}
                    </Tr>
                  )
              )}
            </Tbody>

            {/* Equipments */}
            <Thead>
              <Tr>
                <Th>Equipamentos</Th>
                {proposalRooms?.map((room: ProposalRoom) => (
                  <Th key={room.id} />
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {proposalEquipmentPropertiesArray?.map(
                (property: string, index: number) =>
                  getPropertyKey(property, proposalRooms?.find((f) => f.calculationType === 1) !== undefined) !==
                    null && (
                    <Tr key={String(index)}>
                      <Td>{getPropertyText(property)}</Td>
                      {proposalRooms?.map((room: ProposalRoom) => (
                        <Td key={room.id}>{room?.equipment[property]}</Td>
                      ))}
                    </Tr>
                  )
              )}
            </Tbody>
          </Table>
        </Box>
      )}
      <Text textAlign="left" mt={5} ml={2} fontSize="12" color={colors.gray200} fontWeight="500">
        * Para efeito de calculo de carga termica, não faz parte do fornecimento.
      </Text>
    </Skeleton>
  );
};

export default Characteristics;
